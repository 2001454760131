import React from 'react'
import './expertise.css'

import { FaServer } from 'react-icons/fa'
import { FaPython } from 'react-icons/fa'
import { SiDjango } from 'react-icons/si'
import { FaNodeJs } from "react-icons/fa";
import { TbSql } from 'react-icons/tb'

import { FaLaptop } from 'react-icons/fa'
import { FaHtml5 } from 'react-icons/fa'
import { FaCss3Alt } from 'react-icons/fa'
import { DiJavascript1 } from 'react-icons/di'
import { FaReact } from 'react-icons/fa'
import { FaBootstrap } from 'react-icons/fa'
import { SiPostgresql } from 'react-icons/si'
import { SiTypescript } from "react-icons/si";

import { useInView } from 'react-intersection-observer';


export const Expertise = () => {
  const { ref: expertiseRef, inView: expertiseTitlesVisible } = useInView()

  return (
    <section id="expertise" className="skills_section">
      <div ref={expertiseRef} className="expertise_section_title_container">
        <h5 className={expertiseTitlesVisible ? "expertise_sectionH5 show" : "expertise_sectionH5 hidden"}>Technical</h5>
        <h2 className={expertiseTitlesVisible ? "expertise_sectionH2 show" : "expertise_sectionH2 hidden"}>Expertise</h2>
      </div >


      <div className="skills_outer_container ">
        <div className="container skills_container">
          <div className={expertiseTitlesVisible ? "front_end_container show" : "front_end_container hidden"}>
            <div className="experienced_frontend"><FaLaptop className='laptop' />{'Frontend'}</div>
            <div className="inner_container">
              <article className="icons">
                <FaHtml5 className="icon html" />
                <h4>HTML</h4>
              </article>

              <article className="icons">
                <FaCss3Alt className="icon css" />
                <h4>CSS</h4>
              </article>

              <article className="icons">
                <DiJavascript1 className="icon javascript" />
                <h4>JavaScript</h4>
              </article>

              <article className="icons">
                <FaReact className="icon react" />
                <h4>React</h4>
              </article>

              <article className="icons">
                <SiTypescript className="icon bootstrap" />
                <h4>TypeScript</h4>
              </article>
              <article className="icons">
                <FaBootstrap className="icon bootstrap" />
                <h4>Bootstrap</h4>
              </article>
            </div>
          </div>

          <div className={expertiseTitlesVisible ? "back_end_container show" : "back_end_container hidden"}>
            <div className="experienced_frontend"><FaServer className='server' />{'Backend'}</div>

            <div className="inner_container">
              <article className="icons">
                <FaPython className="icon python" />
                <h4>Python</h4>
              </article>

              <article className="icons">
                <SiDjango className="icon django" />
                <h4>Django</h4>
              </article>

              <article className="icons">
                <FaNodeJs className="icon django" />
                <h4>Node.js</h4>
              </article>

              <article className="icons">
                <TbSql className="icon sql" />
                <h4>SQL</h4>
              </article>

              <article className="icons">
                <SiPostgresql className="icon psql" />
                <h4>SQL</h4>
              </article>

            </div>
          </div>
        </div>
      </div>
    </section >
  )
}
